<template>
  <div>
    <v-dialog v-model="dialogPlanPending" max-width="500px">
      <v-card v-if="dialogPlanPending" class="px-6 py-2">
        <v-card-title style=" word-break: break-word">
          <div>
            <v-btn
              small
              absolute
              top
              right
              icon
              @click="dialogPlanPending = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </div>
          <h4 style="color:#024059" class="ml-0 mb-4">
            Alteração de Plano
          </h4>
        </v-card-title>
        <v-col class="d-flex text-center justify-center mb-5" v-if="loading">
          <v-progress-circular
            :size="50"
            color="#0288D1"
            indeterminate
          ></v-progress-circular>
        </v-col>
        <v-card-text v-else>
          <p class="subtitle-1"
            >Estamos realizando a troca do
            <span class="font-weight-bold"
              >Plano {{ currentPlan.classificationType | translate }}</span
            >
            para o
            <span class="font-weight-bold"
              >Plano {{ desiredPlan.classificationType | translate }}</span
            >
            solicitada no dia
            <span class="font-weight-bold">{{
              dataPlanChangePending.dateRequestChange | moment('LL')
            }}</span
            >.
          </p>
          <br />
          <p class="subtitle-1" v-if="cancelation"
            >Caso deseje cancelar a solicitação
            <a @click="cancelNotification()">clique aqui</a></p
          >
          <v-btn v-else @click="confirmChange()" dark color="#024059">Ok</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    dataPlanChangePending: {
      type: Object,
    },

    cancelation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogPlanPending: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    ...mapGetters({
      listPlans: 'plans/plans',
    }),
  },
  data() {
    return {
      loading: false,
      currentPlan: [],
      desiredPlan: {},
    }
  },
  methods: {
    getDataPlan() {
      this.listPlans.forEach((element) => {
        if (element.id == this.dataPlanChangePending.idCurrentPlan) {
          this.currentPlan = element
        } else if (element.id == this.dataPlanChangePending.idDesiredPlan) {
          this.desiredPlan = element
        }
      })
    },

    cancelNotification() {
      this.loading = true
      this.$store
        .dispatch(
          'planChangeNotification/deletePlanDesiredChange',
          this.dataPlanChangePending.id
        )
        .then((result) => {
          this.loading = false
          this.dialogPlanPending = false
        })
    },

    confirmChange() {
      this.dialogPlanPending = false
      this.$router.push({
        path: '/dados-do-plano',
      })
    },
  },
  async mounted() {
    this.loading = true
    await this.$store.dispatch('plans/getPlans')
    this.getDataPlan()
    this.loading = false
  },
}
</script>
