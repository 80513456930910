<template>
  <v-row justify="center" class="mt-4">
    <v-col cols="10" class="pa-0">
      <planComparativeLoadin
        v-if="loading"
        :listOfCoparativeType="listOfCoparativeType"
      />
      <plansCard
        v-if="!loading"
        :plans="plansByJuridicType"
        @selectedAPlan="changePlan($event)"
        :disabled="btnDisabledPlan"
        :loading="loadingBnt"
        :idSelectPlan="companyPaymentInfo.idPlan"
      />

      <comparativeTable
        :plans="plansByJuridicType"
        :listOfCoparativeType="listOfCoparativeType"
        v-if="!loading"
        :loading="loading"
        :juridicType="juridicType"
        :activities="ActivitiesByPlanClassificationType"
      />

      <plansSmallBtn
        :loading="loadingBnt"
        :plans="plansByJuridicType"
        @selectedAPlan="changePlan($event)"
        :disabled="btnDisabledPlan"
        :idSelectPlan="companyPaymentInfo.idPlan"
      />
    </v-col>
    <changePlanPending
      v-model="openDialogPlanPading"
      v-if="openDialogPlanPading"
      :dataPlanChangePending="planToChange"
    ></changePlanPending>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  paymentMethods,
  paymentComputed,
  companyMethods,
  companyComputed,
} from '@state/helpers'
import comparativeTable from '@/components/compatativePlan/comparativeTable.vue'
import plansCard from '@/components/compatativePlan/plansCard.vue'
import plansSmallBtn from '@/components/compatativePlan/plansSmallBtn.vue'
import planComparativeLoadin from '@/components/compatativePlan/planComparativeLoadin.vue'
import changePlanPending from '@src/components/allPlans/changePlanPending.vue'

export default {
  components: {
    plansCard,
    comparativeTable,
    planComparativeLoadin,
    plansSmallBtn,
    changePlanPending,
  },
  data() {
    return {
      loading: false,
      loadingBnt: false,
      btnDisabledPlan: false,
      openDialogPlanPading: false,
    }
  },
  computed: {
    ...paymentComputed,
    ...companyComputed,
    ...mapGetters({
      listPlans: 'plans/plans',
      planToChange: 'planChangeNotification/planToChange',
    }),
    juridicType() {
      return this.company?.juridicType?.toLowerCase() === 'mei' ? 'mei' : 'me'
    },
    listOfCoparativeType() {
      if (this.juridicType == 'mei') {
        return ['free', 'complete']
      } else {
        return ['basic', 'complete']
      }
    },
    plansByJuridicType() {
      return this.listPlans.filter(
        (plan) => this.juridicType === plan.type.toLowerCase()
      )
    },
    ActivitiesByPlanClassificationType() {
      let temp = {}
      this.plansByJuridicType.map((plan) => {
        temp[plan.classificationType] = plan.adminActivities
      })
      return temp
    },
  },
  methods: {
    ...companyMethods,
    ...paymentMethods,
    changePlan(id) {
      if (!this.planToChange.hasOwnProperty('id')) {
        this.loadingBnt = true
        this.btnDisabledPlan = true
        this.$store
          .dispatch('planChangeNotification/savePlanDesiredChange', {
            idDesiredPlan: id,
          })
          .then((result) => {
            this.openDialogPlanPading = true
            this.loadingBnt = false
          })
      } else {
        this.$router.push({
          path: '/dados-do-plano',
        })
      }
    },
  },
  async mounted() {
    this.loading = true
    await Promise.all([
      this.$store.dispatch('plans/getPlans'),
      this.getPaymentData(),
      this.getCompany(),
    ])
    this.loading = false
  },
}
</script>
